<template>
    <v-container fluid>
        <v-card>
            <v-row>
                <v-toolbar dense
                           dark
                           max-height="50"
                           color="primary">

                    <v-btn icon
                           dark
                           @click="$router.go(-1)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="gateScheduleId > 0">{{gateSchedule.name}}</v-toolbar-title>
                    <v-toolbar-title v-else>{{ $t('common_create') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark
                               text
                               @click="saveGateSchedule()">
                            {{ $t('common_save') }}
                        </v-btn>
                        <div class="text-center ml-5 mt-3" v-if="gateScheduleId > 0">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs"
                                            v-on="on"
                                            dense>
                                        mdi-dots-vertical
                                    </v-icon>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>
                                            <v-btn color="error" tile @click="deleteDialog = !deleteDialog">
                                                <v-icon>
                                                    mdi-delete
                                                </v-icon>
                                                {{ $t('common_delete') }}
                                            </v-btn>
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="mt-3">
                    <v-text-field v-model="gateScheduleId" v-if="gateScheduleId > 0"
                                  disabled
                                  :label="$t('common_id')"></v-text-field>
                    <v-text-field v-model="gateSchedule.name"
                                  :label="$t('common_name')"></v-text-field>

                    <v-text-field v-model="gateSchedule.modifiedOn" v-if="gateScheduleId > 0"
                                  disabled
                                  label="$t('common_edited')"></v-text-field>

                    <v-text-field v-model="gateSchedule.modifiedBy" v-if="gateScheduleId > 0"
                                  disabled
                                  :label="$t('admin_dashboard_gate_schedules_creatededitedby')"></v-text-field>

                    <v-row>
                        <v-col cols="12"
                               sm="12"
                               md="12">
                            <v-checkbox v-model="gateSchedule.enabled"
                                        :label="$t('common_active')"
                                        color="green">
                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12"
                               sm="4"
                               md="1">
                            <v-checkbox v-model="gateSchedule.monday"
                                        :label="$t('common_monday')"
                                        color="green">
                            </v-checkbox>
                        </v-col>
                        <v-col cols="12"
                               sm="4"
                               md="1">
                            <v-checkbox v-model="gateSchedule.tuesday"
                                        :label="$t('common_tuesday')"
                                        color="green">
                            </v-checkbox>
                        </v-col>
                        <v-col cols="12"
                               sm="4"
                               md="1">
                            <v-checkbox v-model="gateSchedule.wednesday"
                                        :label="$t('common_wednesday')"
                                        color="green">
                            </v-checkbox>
                        </v-col>
                        <v-col cols="12"
                               sm="4"
                               md="1">
                            <v-checkbox v-model="gateSchedule.thursday"
                                        :label="$t('common_thursday')"
                                        color="green">
                            </v-checkbox>
                        </v-col>
                        <v-col cols="12"
                               sm="4"
                               md="1">
                            <v-checkbox v-model="gateSchedule.friday"
                                        :label="$t('common_friday')"
                                        color="green">
                            </v-checkbox>
                        </v-col>
                        <v-col cols="12"
                               sm="4"
                               md="1">
                            <v-checkbox v-model="gateSchedule.saturday"
                                        :label="$t('common_saturday')"
                                        color="red">
                            </v-checkbox>
                        </v-col>
                        <v-col cols="12"
                               sm="4"
                               md="1">
                            <v-checkbox v-model="gateSchedule.sunday"
                                        :label="$t('common_sunday')"
                                        color="red">
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12"
                               sm="12"
                               md="12">
                            <v-checkbox v-model="gateSchedule.closedOnHolidays"
                                        :label="$t('admin_dashboard_gate_schedules_closedholidays')"
                                        color="green">
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row justify="space-around"
                           align="center">
                        <v-col style="width: 350px; flex: 0 1 auto;">
                            <h2>{{ $t('common_start') }}:</h2>
                            <v-time-picker v-model="gateSchedule.fromTime"
                                           format="24hr"></v-time-picker>
                        </v-col>
                        <v-col style="width: 350px; flex: 0 1 auto;">
                            <h2>{{ $t('common_end') }}:</h2>
                            <v-time-picker v-model="gateSchedule.toTime"
                                           format="24hr"></v-time-picker>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-row>

            <v-row>
                <v-card flat>
                    <v-card-text>
                        <v-container fluid>
                            
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-row>
            <v-row justify="center">
                <v-dialog v-model="deleteDialog"
                          max-width="320">
                    <v-card>
                        <v-card-title class="text-h5">
                            {{ $t('admin_dashboard_deletionapproval') }} {{gateSchedule.name}}?
                        </v-card-title>
                        <!--<v-card-text></v-card-text>-->
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error"
                                   @click="deleteGate()">
                                <v-icon right>
                                    mdi-delete
                                </v-icon>
                                {{ $t('common_delete') }}
                            </v-btn>
                            <v-btn color="primary"
                                   @click="deleteDialog = !deleteDialog">
                                {{ $t('common_cancel') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>


            <div style="flex: 1 1 auto;"></div>
        </v-card>
        <v-snackbar v-model="snackbar"
                    :timeout="snackbarTimeout">{{snackbarText}}</v-snackbar>
</v-container>
</template>

<script>
    import gateScheduleService from '@/services/gateScheduleService';
    import router from '@/router';

    export default {
        name: 'gateScheduleEdit',
        props: ["gateScheduleId", "gateId"],
        components: {
        },
        data: () => ({
            gateSchedule: {
                id: 0,
                name: 'Schema 1',
                enabled: false,
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: false,
                sunday: false,
                fromTime: '08:00',
                toTime: '17:00',
                closedOnHolidays: true,
                modifiedOn: '',
                gateId: Number,
            },
            deleteDialog: false,
            snackbar: false,
            snackbarText: '',
            snackbarTimeout: 3000
        }),
        methods: {
            deleteGate() {
                gateScheduleService.deleteGateSchedule(this.gateSchedule.id)
                    .then(data => {
                        router.go(-1)
                    });
            },
            saveGateSchedule() {
                if (this.gateSchedule.id > 0) {
                    gateScheduleService.updateGateSchedule(this.gateSchedule)
                        .then(data => {
                            this.snackbarText = data.message;
                            this.snackbar = true;
                            router.push({ name: 'editGate', params: { gateId: parseInt(this.gateSchedule.gateId) } });
                        });
                } else {
                    gateScheduleService.createGateSchedule(this.gateSchedule)
                        .then(resp => {
                            if (resp.data.success) {
                                this.snackbarText = resp.data.message;
                                this.snackbar = true;
                                router.push({ name: 'editGate', params: { gateId: parseInt(this.gateSchedule.gateId) } });
                            }
                        });
                }
            },
            fillView(data) {
                this.gateSchedule = data;
                this.gateSchedule.modifiedOn = new Date(data.modifiedOnUtc).toLocaleString();
            }
        },
        mounted() {
            this.gateSchedule.gateId = parseInt(this.gateId);
            if (this.gateScheduleId > 0) {
                gateScheduleService.getGateSchedule(this.gateScheduleId)
                    .then(data => {
                        this.fillView(data);
                    });
            }
        },
    }
</script>